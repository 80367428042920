import React from "react";
import Footer from '../Footer';
import { Scrollbar } from 'react-scrollbars-custom';
import { Card, CardActionArea, CardMedia, CardContent, Typography } from "@mui/material";
import "../css/Codes.css";

const Codes = () => {

    const websites = [
        {
            name: "DKOTMI",
            link: "https://dkotmi.com",
            media: "https://res.cloudinary.com/angeloron/image/upload/v1732113825/dkotmi_landingpage.jpg",
            description: "A landing page for an IT consulting startup made in NextJS."
        },
        {
            name: "CRM",
            link: "https://dkotmi.com/janitorial-crm",
            media: "https://res.cloudinary.com/angeloron/image/upload/c_scale,f_auto,q_100/yl8whegexdevs44cqeyb",
            description: "A responsive CRM for a janitorial company made in NextJS and Material UI."
        },
        {
            name: "SELF SERVICE",
            link: "https://dkotmi.com/self-service-checkout",
            media: "https://res.cloudinary.com/angeloron/image/upload/v1736076777/self_service_ahglaw.jpg",
            description: "A self service checkout for a canteen made in Electron, NextJS and Material UI."
        },
        {
            name: "E-COMMERCE",
            link: "https://gitlab.com/angeloron/loronpresets",
            media: "https://res.cloudinary.com/angeloron/image/upload/c_scale,f_auto,q_100/v1584109646/loronpresets.jpg",
            description: "An online store with a cart system made in React JS and Material UI. Using Paylike.io as payment system."
        },
        {
            name: "DONATION FORM",
            link: "https://github.com/angeloron/react-material-ui-stripe-payment-form",
            media: "https://github.com/angeloron/react-material-ui-stripe-payment-form/blob/master/preview.gif?raw=true",
            description: "An open source project made to receive online payment with React, Material-UI and Stripe."
        },
        {
            name: "REAL ESTATES TEMPLATE",
            link: "https://gitlab.com/angeloron/estates-landing-page",
            media: "https://res.cloudinary.com/angeloron/image/upload/c_scale,f_auto,q_100/v1585299545/realestatestemplate.jpg",
            description: "An open source Real Estates landing page template controlled by a config.json file which makes it easy to re-use for any other project."
        },
    ]

    return <Scrollbar noScrollX ="true">
        <div id="codeWrapper">
            <div>
                {websites.map(x =>
                    <Card key={x.name} onClick={() => window.open(x.link)} className="card">
                        <CardActionArea>
                            <CardMedia
                                className="cardmedia"
                                image={x.media}
                                title="Loron Presets"
                                style={{backgroundPosition: "top"}}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {x.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {x.description}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                )}
            </div>
        </div>
        <Footer />
    </Scrollbar>
}

export default Codes;